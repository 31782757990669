import React from "react"
import Layout from "../../components/Layout/Layout"
import PageHeader from "../../components/shared/PageHeader"
import DeloreanBlock from "../../components/AboutPage/DeloreanBlock"
import VideoBlock from "../../components/AboutPage/VideoBlock";
import {graphql, StaticQuery} from "gatsby"

const AboutPage = (props) => {
	return(
		<Layout title="About | London | Back to the Future the Musical" description="Take an electrifying ride back in time with Back to the Future The Musical at the Adelphi Theatre in London.">
			<PageHeader
				imageXs={props.data.imageXs}
				imageSm={props.data.imageSm}
				imageLg={props.data.imageLg}
				title="About" />
			<DeloreanBlock london/>
			<VideoBlock/>
		</Layout>
	)
}

export default (props) => (
	<StaticQuery
		query={graphql`
            query {
                imageXs: file(relativePath: { eq: "576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
		render={data => (
			<AboutPage
				{...props}
				data={data}/>
		)}
	/>
)
